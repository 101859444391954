import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import img1 from "../img/carousel/1.png";
import img2 from "../img/carousel/2.png";
import img3 from "../img/carousel/3.png";
import img4 from "../img/carousel/4.png";
import img5 from "../img/carousel/5.png";
import img6 from "../img/carousel/6.png";
import img7 from "../img/carousel/7.png";
import img8 from "../img/carousel/8.png";
import img9 from "../img/carousel/9.png";
import img10 from "../img/carousel/10.png";
import img11 from "../img/carousel/11.png";
import img12 from "../img/carousel/12.png";




const CarouselProducts = () => {
    return(
        <>
            <div id="carouselProduct" className="carousel slide carousel-fade mb-4 view-only" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={img1} className="d-block w-100" alt="terapia respiratoria"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img2} className="d-block w-100" alt="equipo de diagnostico"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img3} className="d-block w-100" alt="material para ortopedia"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img4} className="d-block w-100" alt="bisturí y suturas"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img5} className="d-block w-100" alt="médico quirúrgico"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img6} className="d-block w-100" alt="anestesia"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img7} className="d-block w-100" alt="recolección"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img8} className="d-block w-100" alt="sondas, catéteres"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img9} className="d-block w-100" alt="ginecología"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img10} className="d-block w-100" alt="apósitos"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img11} className="d-block w-100" alt="esterilización"/>
                    </div>
                    <div className="carousel-item">
                        <img src={img12} className="d-block w-100" alt="canalización"/>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProduct" data-bs-slide="prev">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselProduct" data-bs-slide="next">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div id="carouselProductTwo" className="carousel slide carousel-fade mb-4 view-only-two d-none" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex gap-3">
                            <img src={img1} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img2} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img3} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img4} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img5} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img6} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img7} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img8} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img9} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img10} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img11} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img12} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev new-carousel-control-prev" type="button" data-bs-target="#carouselProductTwo" data-bs-slide="prev">
                    <span className="text-white" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next new-carousel-control-next" type="button" data-bs-target="#carouselProductTwo" data-bs-slide="next">
                    <span className="text-white" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>



            <div id="carouselProductTree" className="carousel slide carousel-fade mb-4 view-only-tree d-none" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex gap-3">
                            <img src={img1} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img2} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img3} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img4} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img5} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img6} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img7} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img8} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img9} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img10} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img11} className="d-block w-100" alt="terapia respiratoria"/>
                            <img src={img12} className="d-block w-100" alt="terapia respiratoria"/>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev new-carousel-control-prev" type="button" data-bs-target="#carouselProductTree" data-bs-slide="prev">
                    <span className="text-white" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next new-carousel-control-next" type="button" data-bs-target="#carouselProductTree" data-bs-slide="next">
                    <span className="text-white" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>




            <div id="carouselProductFour" className="carousel slide carousel-fade mb-4 view-only-four d-none" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex gap-3">
                            <img src={img1} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img2} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img3} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img4} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img5} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img6} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img7} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img8} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-3">
                            <img src={img9} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img10} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img11} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                            <img src={img12} className="d-block w-100 shadow-center" alt="terapia respiratoria"/>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev new-carousel-control-prev" type="button" data-bs-target="#carouselProductFour" data-bs-slide="prev">
                    <span className="text-white" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next new-carousel-control-next" type="button" data-bs-target="#carouselProductFour" data-bs-slide="next">
                    <span className="text-white" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    );
}
export default CarouselProducts