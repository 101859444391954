
const Nosotros = () => {

    return(
        <section className="mb-5 overflow-hidden">
            <div className="container">
                <div className="d-flex flex-wrap mb-3 flex-no-wrap-edit" data-aos="flip-down">
                    <h2 className="text-uppercase fw-bold d-flex flex-column welcome-portinova focus-in-expand">
                        <span className="color-dark-gray">Bienvenido</span>
                        <span className="color-blue-light">
                            <span className="color-dark-gray">a</span>&nbsp;portinova</span>
                    </h2>
                    <p className="color-dark-gray container-description-nosotros">
                        Contamos con una trayectoria con más de 15 años en el mercado público y privado en Guatemala, 
                        nos especializamos en la <strong>importación y comercialización</strong> de material médico quirúrgico, somos 
                        distribuidores exclusivos de las marcas <span className="color-blue-light">OPERSON</span> y <span className="color-blue-light">OSEN</span>.
                    </p>
                </div>
                <div className="d-flex justify-content-center" data-aos="zoom-out">
                    <div className="px-4 text-center bordered-daily">
                        <h3 className="color-dark-gray fw-bold">horario de atención</h3>
                        <h4 className="color-dark-gray">Lunes a Viernes de 07.00&nbsp;a 15.30 horas</h4>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Nosotros