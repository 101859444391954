import { useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";
import AOS from 'aos';
import Main from "./Components/Main";
import Nosotros from "./Components/Nosotros";
import Productos from "./Components/Productos";
import Opciones from "./Components/Opciones";
import Footer from "./Components/Footer";
import Contactanos from "./Components/Contactanos";
import Distribuidor from "./Components/Distribuidor";
import Whatsapp from "./Components/Whatsapp";

function App() {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
      <Wrapper>
        <Main/>
        <Nosotros/>
        <Productos/>
        <Opciones/>
        <Distribuidor/>
        <Contactanos/>
        <Footer/>
        <Whatsapp/>
      </Wrapper>
    </>
  );
}

export default App;
