import { FaWhatsapp} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Whatsapp = () => {
    return(
        <NavLink to="https://wa.me/+50223546565" rel="noopener noreferrer nofollow" target="_blank">
            <FaWhatsapp className="logo-whatsapp z-50 pulse-green" size={60}/>
        </NavLink>
    )
}

export default Whatsapp