import portada from "../img/portada.png";
import video from "../img/video.mp4";
import pulse from "../img/pulse.gif";

const Opciones = () => {
    return(
        <section className="mb-5 overflow-hidden">
            <div className="container">
                <div className="d-flex flex-wrap gap-4 flex-no-wrap-edit">
                    <div>
                        <video poster={portada} className="w-100 h-100 object-fit-cover video-init" autoPlay loop muted>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <div className="d-flex gap-2">
                            <div className="w-container-pulse">
                                <figure className="m-0 d-flex">
                                    <img src={pulse} className="w-100 h-100" alt="pulse"/>
                                </figure>
                            </div>
                            <div className="w-container-opciones" data-aos="fade-right">
                                <h2 className="h2-size-opciones">Amplio <strong>inventario disponible</strong></h2>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <div className="w-container-pulse">
                                <figure className="m-0">
                                    <img src={pulse} className="w-100 h-100" alt="pulse"/>
                                </figure>
                            </div>
                            <div className="w-container-opciones" data-aos="fade-left">
                                <h2 className="h2-size-opciones">Distribución en toda Guatemala</h2>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <div className="w-container-pulse">
                                <figure className="m-0">
                                    <img src={pulse} className="w-100 h-100" alt="pulse"/>
                                </figure>
                            </div>
                            <div className="w-container-opciones" data-aos="fade-right">
                                <h2 className="h2-size-opciones">Sin mínimo de compra</h2>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <div className="w-container-pulse">
                                <figure className="m-0">
                                    <img src={pulse} className="w-100 h-100" alt="pulse"/>
                                </figure>
                            </div>
                            <div className="w-container-opciones" data-aos="fade-left">
                                <h2 className="h2-size-opciones">Distribuidores exclusivos OPERSON&nbsp;y OSEN</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Opciones;