import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import React from 'react';

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/productos.css";
import "./CSS/contactanos.css";
import "./CSS/distribuidor.css";
import "./CSS/nosotros.css";
import "./CSS/opciones.css";
import "aos/dist/aos.css";
import "./CSS/header.css";
import "./CSS/main.css";
import './index.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);