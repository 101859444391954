import distribuidor from "../img/distribuidor.png";

const Distribuidor = () => {
    return(
        <section className="mb-5 position-relative section-proveider">
            <div className="container container-title-proveider overflow-hidden">
                <h2 className="fw-bold d-flex flex-column title-proveider" data-aos="zoom-in">
                    <span>Conviértete</span>
                    <span>en distribuidor</span>
                </h2>
            </div>
            <div className="overflow-hidden">
                <div className="container-description-suplier" data-aos="fade-right">
                    <div className="container py-5 text-end text-white container-data-proveider">
                        <h4>
                            Ofrecemos el mejor valor a nuestros socios centrándonos en productos 
                            asequibles de alta calidad y soluciones personalizadas para mercados 
                            específicos. Únete a nuestra red global establecida de distribuidores, hospitales 
                            e instituciones públicas y privadas.
                        </h4>
                        <div className="d-flex justify-content-end">
                            <a className="px-4 py-3 btn-go-contact pulse" href="#contacto">
                                <h4 className="text-uppercase">ponerme en contacto</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-figure-proveider overflow-hidden">
                <img src={distribuidor} className="figure-proveider w-100 h-100" alt="distribuidor" data-aos="fade-left"/>
            </div>
        </section>
    );
}

export default Distribuidor