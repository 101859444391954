import { useState } from "react";
import partners from "../img/partners.png";
import response from "../Helpers/sendMail";

const Contactanos = () => {
    const [inputMensaje, setInputMensaje] = useState('');
    const [messageError, setMessageError] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [allValues, setAllValues] = useState(true);
    const [SendMail, setSendMail] = useState(false);
    const [inputName, setInputName] = useState('');
    const [inputMail, setInputMail] = useState('');
    const [preSend, setPreSend] = useState(false);

    const handleInputName = (e) => {
        setInputName(e.target.value);
    }
    const handleInputPhone = (e) => {
        setInputPhone(e.target.value);
    }
    const handleInputMail = (e) => {
        setInputMail(e.target.value);
    }
    const handleInputMensaje = (e) => {
        setInputMensaje(e.target.value);
    }

    const validatePhone = (phone) => {
        return phone.length === 8
    }

    const timeOutErrorMessage = (messageErr) => {
        setMessageError(messageErr)
        setAllValues(false)
        setTimeout(() => {
            setAllValues(true);
        }, 3000);
    }

    const handleValidation = () => {
        if (inputName !== '' && inputPhone !== ''){
            if (validatePhone(inputPhone)){
                setAllValues(true)
                setMessageError('')
            }
            else{
                timeOutErrorMessage('Ingrese un número teléfonico valido')
            }
        }
        else{
            timeOutErrorMessage('No se han completado todos los campos... ¡Por favor completa todos los campos!')
        }
    }

    const handleSendMail = async () => {
        let data = {
            'name': inputName,
            'phone': inputPhone,
            'mail': inputMail,
            'message': inputMensaje
        }
        
        const res = await response(data)
        
        if(res.status === 200){
            setPreSend(false)
            setSendMail(true);
            setAllValues(true)
            setTimeout(() => {
                setSendMail(false);
            }, 3000);
        }
        else{
            setSendMail(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (allValues){
            setPreSend(true)
            await handleSendMail();
            setInputMensaje('');
            setInputPhone('');
            setInputName('');
            setInputMail('');
        }
    }
    
    return(
        <section className="mb-5 section-contact overflow-hidden position-relative">
            <div className="overlay-skew"></div>
            <div id="contacto" className="container text-white container-contact">
                <div className="d-flex flex-wrap gap-3 mb-5 container-data-contact">
                    <div className="w-100 container-question-contact">
                        <h2 className="fw-bold mb-3">¿Deseas que te contactemos?</h2>
                        <h4 className="mb-3">Siendo importadores directos y exclusivos, obtienes el mejor retorno de tu inversión.</h4>
                        <h4 className="mb-3">Déjanos tus datos y con gusto te contactaremos.</h4>
                        <figure>
                            <img src={partners} className="w-100 h-100" alt="partners"/>
                        </figure>
                    </div>
                    <div className="w-100">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="inputName" className="form-label">Nombre</label>
                                <input type="text" name="name" className="form-control" id="inputName" value={inputName} onChange={handleInputName}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputNumero" className="form-label">Número</label>
                                <input type="tel" name="phone" className="form-control" id="inputNumero" value={inputPhone} onChange={handleInputPhone}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputMail" className="form-label">Correo Electrónico</label>
                                <input type="email" name="email" className="form-control" id="inputMail" value={inputMail} onChange={handleInputMail}/>
                            </div>
                            <div className="mb-5">
                                <label htmlFor="inputDescription" className="form-label">Mensaje</label>
                                <textarea name="description" className="form-control" id="inputDescription" value={inputMensaje} onChange={handleInputMensaje}></textarea>
                            </div>
                            <div className={`alert alert-danger d-flex align-items-center ${!allValues ? 'show-incomplete': 'd-none' }`} role="alert">
                                {messageError}
                            </div>
                            <div className={`alert alert-warning ${preSend ? 'show-incoming': 'd-none' }`} role="alert">
                                Enviando correo...
                            </div>
                            <div className={`alert alert-success ${SendMail ? 'show-success': 'd-none' }`} role="alert">
                                Gracias, con gusto nos pondremos en contacto
                            </div>
                            <div className="d-flex justify-content-end">
                                <button type="submit" onClick={handleValidation} className={`border-0 btn-send-contact px-5 py-3 text-white fw-bold ${preSend ? 'd-none' : ''}`}>Enviar</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            
        </section>
    );
}

export default Contactanos;