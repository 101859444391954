import React, { useState, useRef, useEffect } from 'react';
import CarouselProducts from '../Helpers/CarouselProduct';
import { NavLink } from 'react-router-dom';

const Productos = () => {

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIsVisible(entry.isIntersecting);
          },
          {
            root: null, // Observa en relación al viewport
            rootMargin: '0px',
            threshold: 0.1 // Se activa cuando al menos el 10% del elemento está visible
          }
        );
        const current = elementRef.current
        if (current) {
          observer.observe(current);
        }
    
        // Limpieza para evitar fugas de memoria
        return () => {
          if (current) {
            observer.unobserve(current);
          }
        };
    }, []);

    return(
        <section className="mb-5 section-products">
            <div id="productos" className="container py-5 text-white">
                <div className="mb-4 d-flex flex-column align-items-center">
                    <h2 ref={elementRef} className={`fw-bold text-center title-products ${isVisible?"writing":""}`}>Productos</h2>
                    <h3 className='text-start container-description-products'>
                        <span>Tenemos en inventario una extensa y diversa </span>
                        <span>variedad de productos de alta calidad.</span>
                    </h3>
                </div>

                <CarouselProducts/>

                <div id="catalogo" className="d-flex justify-content-center">
                    <NavLink className="px-4 py-3 btn-download-product pulse" to='https://heyzine.com/flip-book/9d3ff9cb58.html' rel="noopener noreferrer nofollow" target="_blank">
                        <h2>Ver Catálogo</h2>
                    </NavLink>
                </div>
            </div>
        </section>
    );
}

export default Productos;