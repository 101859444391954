import { PiCopyrightLight } from "react-icons/pi";
import { NavLink } from "react-router-dom";
import logo from "../logo.png"

const Footer = () => {
    return(
        <footer>
            <div className="container color-dark-gray">
                <h2 className="fw-bold mb-3">Contáctanos</h2>
                <div className="lh-sm mb-4">
                    <h3>Ubicación:</h3>
                    <h4>27 Avenida 7-25 Col, El Naranjo Bodega 11 Zona 4 de Mixco, Guatemala</h4>
                </div>
                <div className="lh-sm">
                    <h3>Número de Contacto:</h3>
                    <h4><NavLink to="tel:+50223546565">(502) 2354-6565</NavLink></h4>
                    <h4>Lunes a Viernes de 07.00 a 15.30 horas</h4>
                </div>
                <div className="container-footer-logo">
                    <figure className="container-footer-logo">
                        <img src={logo} className="w-40 h-75" alt="logo"/>
                    </figure>
                </div>
            </div>
            <div className="p-2 d-flex justify-content-center gap-2 footer-section-second footer-font">
                <span>
                    <span><PiCopyrightLight/></span>
                    <span className="text-uppercase"> copyright 2024</span>
                </span>
                <span>|</span>
                <span className="text-uppercase"><NavLink to="/">portinova</NavLink></span>
                <span>|</span>
                <span className="text-uppercase"><NavLink to="https://www.yetiadvisers.com/" rel="noopener noreferrer nofollow" target="_blank">powered by yeti advisers</NavLink></span>
            </div>
        </footer>
    );
}
export default Footer